import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { OfferEditMode, OfferType, PermanentOfferDto } from '@libs/shared/models/offer.model';
import { TechnicianProfileModel, UserProfile } from '@libs/shared/models/technician-profile.model';
import { isEmpty, isNil } from 'lodash-es';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { OperatorFunction, pipe, Subscription } from 'rxjs';
import { getAirplanesSortedByName, getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { take } from 'rxjs/operators';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { AmeLicenseType } from '@libs/shared/models/ame-license-type.model';
import { AmeLicenseLevel } from '@libs/shared/models/ame-license-level.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getLocations } from '@libs/shared/bms-common/environment/environment.selector';
import { LocationPO } from '@libs/shared/models/location.model';
import {
  AdditionalBenefitForm,
  AdditionalBenefitFormUtils
} from '@libs/create-offer-page/components/additional-benefits-form.model';
import { AdditionalBenefit, BenefitType, benefitTypeToReadable } from '@libs/shared/models/additional-benefit.model';
import {
  FailedToSubmitPermanentOffer,
  PermanentOfferSubmitSuccess,
  SubmitPermanentOffer
} from './create-permanent-offer.action';
import {
  AviationCompany,
  JobOpeningCompanyOptions,
  MroFacilityLoaderService
} from '../../../shared/services/mro-facility-loader.service';
import { role, RoleWrapper } from '@libs/shared/models/roles.enum';
import { Aircraft } from '@libs/shared/models/aircraft.model';

@Component({
  selector: 'staffnow-create-permanent-offer-page',
  templateUrl: './create-permanent-offer-page.component.html',
  styleUrls: ['./create-permanent-offer-page.component.scss']
})
export class CreatePermanentOfferPageComponent implements OnInit, OnDestroy {
  public readonly PRESENTATION_MAX_LENGTH = 8000;
  getLabelFunction: (item: string) => string = item => item;
  preselectedFilters: PermanentOfferDto;
  selectedTechnicians: { [key: string]: TechnicianProfileModel } = null;
  breadcrumbs: string[] = ['Job Openings and Applications', 'Create Permanent Job Opening'];
  isSettingInitialValues: boolean = true;
  isCustomWorkingPatternSelected: boolean = false;
  facilityProfile: AviationCompany;
  offersAreHandledCentrally: boolean = false;
  rangePickerMinDate: Date = new Date();
  ameNomenclatureOptions: AmeLicenseType[] = [];
  offerForm: FormGroup;
  ameLicenseTypeOptions: AmeLicenseLevel[] = [];
  aircraftList: Array<Aircraft> = [];
  selectedAircraftList: Array<Aircraft> = [];
  locationList: LocationPO[] = [];
  selectedLocation: LocationPO = null;
  fixedWorkingPatterns = [
    {
      daysOn: 5,
      daysOff: 2
    },
    {
      daysOn: 4,
      daysOff: 3
    },
    {
      daysOn: 6,
      daysOff: 1
    }
  ];
  benefits: Array<AdditionalBenefitForm> = AdditionalBenefitFormUtils.getDefault();
  isSingletonNomenclature: boolean = false;
  isSubmitting: boolean = false;
  wrappedRole: RoleWrapper;
  private readonly mroUuid: string = '';
  private subscriptions: Subscription = new Subscription();
  private readonly FILTERS_KEY: string = 'FILTERS_PERMANENT';
  private isPrivate: boolean = false;
  isEdit: boolean = false;

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private router: Router,
    private customNavigationService: CustomNavigationService,
    private fb: FormBuilder,
    private mroFacilityLoaderService: MroFacilityLoaderService
  ) {
    const navigationState =
      this.router.getCurrentNavigation()?.extras?.state || JSON.parse(sessionStorage.getItem(this.FILTERS_KEY));

    if (navigationState) {
      sessionStorage.setItem(this.FILTERS_KEY, JSON.stringify(navigationState));
      const { selectedTechnicians, preselectedFilters, breadcrumbs, mroUuid, isEdit } = navigationState;
      this.selectedTechnicians = selectedTechnicians;
      this.preselectedFilters = preselectedFilters;
      this.isPrivate = navigationState.isPrivate;
      this.isEdit = isEdit;
      this.mroUuid = mroUuid;
      this.breadcrumbs = breadcrumbs ?? ['Job Openings and Applications', 'Create Permanent Job Opening'];
    }
    this.subscriptions.add(
      this.actions.pipe(ofType(PermanentOfferSubmitSuccess)).subscribe(action => {
        this.isSubmitting = false;
        sessionStorage.removeItem(this.FILTERS_KEY);
        if (action.refNumber && !this.wrappedRole.isAdminOrModerator()) {
          this.customNavigationService.goToOfferDetails(action.refNumber, OfferType.PERMANENT, false);
        } else {
          this.customNavigationService.goBack();
        }
      })
    );
    this.subscriptions.add(
      this.actions.pipe(ofType(FailedToSubmitPermanentOffer)).subscribe(() => {
        this.isSubmitting = false;
      })
    );
    this.storeSubscribe(pipe(getLoggedInUserRole, take(1)), userRole => {
      this.wrappedRole = role(userRole);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    sessionStorage.removeItem(this.FILTERS_KEY);
  }

  ngOnInit(): void {
    this.mroFacilityLoaderService
      .getFacilityProfile(this.mroUuid)
      .pipe(take(1))
      .subscribe((options: JobOpeningCompanyOptions) => {
        this.initForm(options.facilityProfile);
      });
  }

  private initForm(facilityProfile: AviationCompany): void {
    this.offerForm = this.fb.group({
      uuid: [this.preselectedFilters.uuid || null],
      useCustomReference: [false],
      priority: ['MEDIUM', Validators.required],
      refNumber: [{ value: this.preselectedFilters?.refNumber || '', disabled: true }],
      startDate: [this.preselectedFilters?.startDate || '', Validators.required],
      title: [this.preselectedFilters?.title || ''],
      locationId: [
        !isNil(this.preselectedFilters?.location?.id) ? this.preselectedFilters?.location?.id : 0,
        Validators.required
      ],
      ameType: [this.preselectedFilters?.ameTitle?.type || null, Validators.required],
      ameTitleId: [
        !isNil(this.preselectedFilters?.ameTitle?.id) ? this.preselectedFilters?.ameTitle?.id : 0,
        Validators.required
      ],
      airplanes: [this.preselectedFilters?.airplanes || '', Validators.required],
      otherAirplanes: [this.preselectedFilters?.otherAirplanes || ''],
      minExperience: [this.preselectedFilters?.minExperience || null, Validators.required],
      hasHiddenRate: [this.preselectedFilters?.hasHiddenRate],
      vacancies: [this.preselectedFilters?.vacancies || null, Validators.required],
      netSalaryPerMonth: [this.preselectedFilters?.monthlySalary?.amount || null, Validators.required],
      workDaysOn: [this.preselectedFilters?.workDaysOn || '', Validators.required],
      workDaysOff: [this.preselectedFilters?.workDaysOff || '', Validators.required],
      shortPresentation: [this.preselectedFilters?.shortPresentation || '', Validators.required],
      additionalBenefits: [this.preselectedFilters?.additionalBenefits || ''],
      allowTcnApplications: [
        isNil(this.preselectedFilters?.allowTcnApplications) ? true : this.preselectedFilters?.allowTcnApplications
      ],
      mroUuid: ['', Validators.required]
    });

    this.ameNomenclatureOptions = getEmbeddedResource(facilityProfile, FacilityProfileLinkRel.AmeNomenclature);

    if (this.isEdit) {
      this.initializeBenefitsForEditForm(this.preselectedFilters.additionalBenefits);
      this.initWorkingPatternForEditForm();
      if (this.isRestrictedMode()) {
        this.disableFieldsOnRestrictedMode();
      }
      const selectedAmeType = this.ameNomenclatureOptions.find(
        option => option.name == this.preselectedFilters.ameTitle.type
      );
      this.ameLicenseTypeOptions = selectedAmeType ? selectedAmeType.licenses : [];
    } else {
      this.offerForm.controls.ameTitleId.setValue(null);
    }

    this.facilityProfile = facilityProfile;
    this.offersAreHandledCentrally = facilityProfile.offersHandledCentrally;
    this.ameNomenclatureOptions = getEmbeddedResource(facilityProfile, FacilityProfileLinkRel.AmeNomenclature);
    this.offerForm.controls.mroUuid.setValue(facilityProfile.uuid);
    this.storeSubscribe(getLocations, locationList => {
      this.locationList = locationList;
      this.selectedLocation = this.locationList.find(location => location.name == this.facilityProfile.location);
      this.writeLocationIdToForm();
    });
    this.storeSubscribe(getAirplanesSortedByName, aircraftList => {
      this.aircraftList = aircraftList;
      if (this.isEdit) {
        this.selectedAircraftList = aircraftList.filter(aircraft =>
          this.preselectedFilters.airplanes?.includes(aircraft.id)
        );
      }
    });
    this.isSettingInitialValues = false;
  }

  private initializeBenefitsForEditForm(benefits: AdditionalBenefit[]): void {
    benefits.forEach((preselectedBenefit: AdditionalBenefit) => {
      const benefitToUpdateIndex = this.benefits.findIndex(benefit => benefit.type === preselectedBenefit.type);
      this.benefits[benefitToUpdateIndex].isSelected = true;
      this.benefits[benefitToUpdateIndex].amount = preselectedBenefit.amount['amount'] || null;
      this.benefits[benefitToUpdateIndex].description = preselectedBenefit.description;
    });
  }

  private storeSubscribe<T, S>(pipedSelector: OperatorFunction<T, S>, subscribeFn: (a: S) => void): void {
    this.subscriptions.add(this.store.pipe(pipedSelector).subscribe(subscribeFn));
  }

  noSelectedTechnicians(): boolean {
    return isNil(this.selectedTechnicians) || isEmpty(this.selectedTechnicians);
  }

  handleRemoveFromSelectedTechnicians(technicianToRemove: TechnicianProfileModel): void {
    const { userUuid } = getEmbeddedResource<UserProfile>(technicianToRemove, UserProfileLinkRel.Profile);
    delete this.selectedTechnicians[userUuid];
  }

  toggleCustomReference($event: Event): void {
    if (($event.target as HTMLInputElement).checked) {
      this.offerForm.controls.refNumber.enable();
      this.offerForm.controls.refNumber.addValidators(Validators.required);
    } else {
      this.offerForm.controls.refNumber.disable();
      this.offerForm.controls.refNumber.removeValidators(Validators.required);
    }
  }

  writeLocationIdToForm(): void {
    this.offerForm.controls.locationId.setValue(this.selectedLocation?.id);
  }

  getAmeLicenseOptions($event: Event): void {
    const ameType: string = ($event.target as HTMLInputElement).value;
    if (isEmpty(this.ameNomenclatureOptions)) {
      return;
    }
    this.offerForm.controls.ameTitleId.setValue(null);
    const selectedAmeType = this.ameNomenclatureOptions.find(option => option.name == ameType);
    this.ameLicenseTypeOptions = selectedAmeType ? selectedAmeType.licenses : [];
    this.isSingletonNomenclature = selectedAmeType?.isSingleton;
    if (this.isSingletonNomenclature) {
      this.offerForm.controls.ameTitleId.setValue(this.ameLicenseTypeOptions[0].id);
    }
  }

  updateSelectedAircrafts(airplanes: any): void {
    const airplaneIdList = airplanes.map(airplane => airplane.id);
    this.offerForm.controls.airplanes.setValue(airplaneIdList);
    this.offerForm.controls.airplanes.markAsDirty();
  }

  get currencySymbol(): string {
    return (this.facilityProfile as any)?.workingCurrency;
  }

  isWorkingPatternSelected(workingPattern: { daysOn: number; daysOff: number }): boolean {
    const { workDaysOn, workDaysOff } = this.offerForm.value;
    return workDaysOff === workingPattern.daysOff && workDaysOn === workingPattern.daysOn;
  }

  setWorkingPattern(workingPattern: { daysOn: number; daysOff: number }): void {
    this.isCustomWorkingPatternSelected = false;
    this.offerForm.controls.workDaysOn.setValue(workingPattern.daysOn.toString());
    this.offerForm.controls.workDaysOff.setValue(workingPattern.daysOff.toString());
  }

  enableCustomWorkingPattern(): void {
    this.isCustomWorkingPatternSelected = true;
  }

  benefitLabel(type: BenefitType): string {
    return benefitTypeToReadable(type);
  }

  benefitSelected(event: Event, benefit: AdditionalBenefitForm) {
    const checked: boolean = (event.target as HTMLInputElement).checked;
    const index = this.benefits.indexOf(benefit);
    const benefitToEdit = { ...this.benefits[index] };
    benefitToEdit.isSelected = checked;
    this.benefits[index] = benefitToEdit;
  }

  benefitIsSelected(benefit: AdditionalBenefitForm): boolean {
    const index = this.benefits.indexOf(benefit);
    return this.benefits[index].isSelected;
  }

  addAmountToBenefit($event: Event, benefit: AdditionalBenefitForm): void {
    const htmlInputElement: HTMLInputElement = $event.target as HTMLInputElement;
    const index = this.benefits.indexOf(benefit);
    this.benefits[index].amount = htmlInputElement.value === '' ? null : Number(htmlInputElement.value);
  }

  addDescriptionToBenefit($event: Event, benefit: AdditionalBenefitForm): void {
    const htmlInputElement: HTMLInputElement = $event.target as HTMLInputElement;
    const index = this.benefits.indexOf(benefit);
    this.benefits[index].description = htmlInputElement.validity.valid ? htmlInputElement.value : null;
  }

  requiresAmount(benefit: AdditionalBenefitForm): boolean {
    return AdditionalBenefitFormUtils.requiresAmount(benefit.type);
  }

  isBenefitValid(benefit: AdditionalBenefitForm): boolean {
    return AdditionalBenefitFormUtils.isValid(benefit);
  }

  areBenefitsValid(): boolean {
    return this.benefits
      .filter(benefit => benefit.isSelected)
      .every(benefit => AdditionalBenefitFormUtils.isValid(benefit));
  }

  cancelCreation(): void {
    this.customNavigationService.goBack();
    sessionStorage.removeItem(this.FILTERS_KEY);
  }

  handleFormSubmit(): void {
    this.isSubmitting = true;
    const selectedBenefits: any = this.benefits.filter(benefit => benefit.isSelected);
    this.offerForm.controls.additionalBenefits.setValue(selectedBenefits);
    if (this.isEdit) {
      this.store.dispatch(
        SubmitPermanentOffer({
          payload: { ...this.offerForm.getRawValue(), isPublic: !this.isPrivate },
          editUrl: getUrl(this.preselectedFilters, 'edit')
        })
      );
    } else {
      this.store.dispatch(SubmitPermanentOffer({ payload: this.offerForm.value }));
    }
  }

  onTcnApplicationToggleChange(event: Event) {
    this.offerForm.controls.allowTcnApplications.setValue(!event.currentTarget['checked']);
  }

  private initWorkingPatternForEditForm() {
    const { workDaysOn, workDaysOff } = this.offerForm.value;
    this.isCustomWorkingPatternSelected = !this.fixedWorkingPatterns.some(
      workingPattern => workingPattern.daysOff === workDaysOff && workDaysOn === workDaysOn
    );
  }

  public isRestrictedMode(): boolean {
    const editModeKey = 'editMode';
    if (!Object.prototype.hasOwnProperty.call(this.preselectedFilters, editModeKey)) {
      return false;
    }
    const mode = this.preselectedFilters[editModeKey];
    return !isNil(mode) && mode === OfferEditMode.RESTRICTED;
  }

  private disableFieldsOnRestrictedMode(): void {
    const alwaysActiveControls = ['isPublic', 'priority', 'vacancies'];
    Object.keys(this.offerForm.controls).forEach(controlKey => {
      if (!alwaysActiveControls.includes(controlKey)) {
        this.offerForm.controls[controlKey].disable();
      }
    });
  }
}
